import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PeopleGrid from '../components/people-grid/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import VideoBanner from '../components/video-banner/video-banner'

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/about/" }) {
        id
      title
      _rawBody
			_rawSubtitle
      useVideo
      pageBranding {
        asset {
          _id
        }
      }
      mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
    }
    people: allSanityPerson {
      edges {
        node {
          id
          image {
            asset {
              _id
            }
          }
          name
          _rawBio
        }
      }
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props
  const page = (typeof data === "object" && Object.keys(data).length > 0 ? data.page : {}); 

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const personNodes = data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      { page ? <VideoBanner { ...page}/> : <h1 style={{textAlign: 'center', padding: 180, marginTop: 20, backgroundColor: '#1d8cf8'}}>About Us</h1> }
      <Container>
        <BlockContent blocks={page._rawBody || []} />
        {personNodes &&
          personNodes.length > 0 && (
            <PeopleGrid items={personNodes} title="People" />
          )}
      </Container>
    </Layout>
  )
}

export default AboutPage
